<!--
 * @Author: wxy
 * @Date: 2022-09-26 10:54:04
 * @LastEditTime: 2022-09-26 10:54:16
 * @Description: 全部工单-列表
-->
<template>
  <div>全部工单列表</div>
</template>

<script>
export default {};
</script>

<style>

</style>